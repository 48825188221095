// Import Angular Material theming functions
@use "@angular/material" as mat;

// Define a custom black palette
$black-palette: (
  50: #e0e0e0,
  100: #b3b3b3,
  200: #808080,
  300: #4d4d4d,
  400: #333333,
  500: #000000,
  // Main black color
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  A100: #000000,
  A200: #000000,
  A400: #000000,
  A700: #000000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    // White contrast for darker shades
    400: #ffffff,
    500: #ffffff,
    // White contrast for main black
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// Define the color palette for your theme
$black-primary: mat.define-palette($black-palette, 500);
$black-accent: mat.define-palette(mat.$grey-palette, 500, A100, A400);
$black-warn: mat.define-palette(mat.$red-palette);

// Create your custom theme using the black palette
$black-theme: mat.define-light-theme(
  (
    color: (
      primary: $black-primary,
      accent: $black-accent,
      warn: $black-warn,
    ),
    typography: mat.define-typography-config(),
  )
);

// Apply the core styles and theme
@include mat.core();

mat-spinner {
  @include mat.all-component-themes($black-theme);
}
